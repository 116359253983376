import React from "react";
import CookieConsent from "react-cookie-consent";

export default () => (
  <CookieConsent
    location="bottom"
    buttonText="Selvä!"
    cookieName="myAwesomeCookieName2"
    style={{
      background: "rgba(0,0,0,0.8)",
      color: "#fff",
      margin: 20,
      maxWidth: 400,
      left: "auto",
      right: 0,
      boxShadow: "0 0 40px rgba(0,0,0,0.1)",
      justifyContent: "flex-end",
      zIndex: 2147483647,
      width: "auto",
      alignItems: "center",
      borderRadius: 4
    }}
    contentStyle={{ flex: 1, margin: "0 15px", padding: "10px 0" }}
    buttonStyle={{
      background: "#7844A6",
      color: "#fff",
      fontSize: "16px",
      fontFamily: "Rubik, sans-serif",
      // marginTop: 0,
      float: "right",
      padding: "8px 20px",
      borderRadius: 5
    }}
    expires={150}
  >
    <div
      style={{
        fontSize: 13,
        lineHeight: "20px",
        fontFamily: "Rubik, sans-serif"
        // marginBottom: -20
      }}
    >
      Tämä verkkosivu käyttää evästeitä parhaan selailukokemuksen takaamiseksi.
    </div>
  </CookieConsent>
);
