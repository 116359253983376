import React from "react";
import Modal from "antd/es/modal/Modal";
import styled from "styled-components";
import { Button } from "../components";

const ThankYouWidget = styled("div")`
  font-size: 18px;
  text-align: center;

  img {
    max-width: 300px;
    margin-bottom: 50px;
  }
`;

const ThankYouModal = props => (
  <Modal
    style={{ top: 20, maxWidth: 500 }}
    width={null}
    title="Thank You For Your Order"
    visible={props.visible}
    onOk={props.handleOk}
    onCancel={props.handleOk}
    footer={[
      <Button onClick={props.handleOk} key="0">
        Tehty!
      </Button>
    ]}
  >
    <ThankYouWidget>
      <img src="./thank-you.svg" alt="Thank You!" />
      <p>
        <b>Kiitos tilauksestasi.</b> Olemme sinuun yhteydessä 24 tunnin sisään
        liittyen soittoajan järjestämiseen.
      </p>
      <p>
        Jos on kysyttävää, älä epäröi laittaa sähköpostia osoitteeseen{" "}
        <a href="mailto:hi@perspektives.net">hi@perspektives.net</a> <br />{" "}
        Vastaamme aina 24 tunnin sisään.
      </p>
    </ThankYouWidget>
  </Modal>
);

export default ThankYouModal;
