import React from "react";
import Markdown from "markdown-it";

import Modal from "antd/es/modal/Modal";
import { Logo, Section, Button } from "./components";

const md = new Markdown();
const date = new Date();
const month = date.toLocaleString("default", { month: "long" });

const html = md.render(`
Last updated: **${month} ${date.getFullYear()}**

Please read this Terms and Conditions Agreement ("Terms", "Terms and Conditions", "Agreement") carefully before using the https://perspektives.net website (the "Service") operated by Perspektives Digital Oy ("Perspektives").

Your access to and use of the Service is conditioned on your acceptance of and compliance with this Agreement. These Terms apply to all visitors, users and others who access or use the Service.

By accessing or using the Service you ("Client") agree to be bound by this Agreement. If you disagree with any part of the terms then you may not access the Service. If you do not agree with all of the provisions set forth in this Agreement, you should not continue to view this Website, and you should not purchase any service or product from this Website. 

## 1. Purchases

If you wish to purchase any product or service made available through the Service ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your name, work email and phone number ("Personal Data").

## 2. Services and Payment

Perspektives agrees to undertake and complete the Services (as defined in Services Packages) in accordance with and on the schedule specified in Services Packages, or as otherwise agreed separately. As the only consideration due Perspektives regarding the subject matter of this Agreement, Client will pay Perspektives in accordance with purchased Services Package.
The term will continue until the Services are completed or the Agreement is terminated by either party.

## 2.2 Expense reimbursement

Limited to required, reasonable telephone expenses, coach class (or equivalent) transportation, lodging and meals that have been authorized in writing by Client in advance; payable 30 days after receipt of itemized invoice.

## 3. Ownership, Property Rights

Client shall own all right, title and interest (including patent rights, copyrights, trade secret rights, mask work rights, trademark rights, sui generis database rights and all other rights of any sort throughout the world) relating to any and all inventions (whether or not patentable), works of authorship, mask works, designations, designs, know-how, ideas and information made or conceived or reduced to practice, in whole or in part, by Perspektives in connection with Services or any Proprietary Information (as defined below) (collectively, "Inventions") and Perspektives will promptly disclose and provide all Inventions to Client.

## 4. Proprietary Information

Perspektives agrees that all Inventions and all other business, technical and financial information Perspektives develops, learns or obtains in connection with Services or that are received by or for Client in confidence, constitute "Proprietary Information." Perspektives will hold in confidence and not disclose or, except in performing the Services, use any Proprietary Information. However, Perspektives shall not be obligated under this paragraph with respect to information Perspektives can document is or becomes readily publicly available without restriction through no fault of Perspektives. Upon termination and as otherwise requested by Client, Perspektives will promptly return to Client all items and copies containing or embodying Proprietary Information, except that Perspektives may keep its personal copies of its compensation records and this Agreement. 
  
## 5. Non-solicitation

As additional protection for Proprietary Information, Perspektives agrees that during the period over which it is (or is supposed to be) providing Services (i) and for one year thereafter, Perspektives will not encourage or solicit any employee or consultant of Client to leave Client for any reason, and (ii) Perspektives will not engage in any activity that is in any way competitive with the business or demonstrably anticipated business of Client, and Perspektives will not assist any other person or organization in competing or in preparing to compete with any business or demonstrably anticipated business of Client.

## 6. Warranty

Perspektives warrants that: (i) the Services will be performed in a professional and workmanlike manner and that none of such Services or any part of this Agreement is or will be inconsistent with any obligation Perspektives may have to others; (ii) all work under this Agreement shall be Perspektives’s original work and none of the Services or Inventions or any development, use, production, distribution or exploitation thereof will infringe, misappropriate or violate any intellectual property or other right of any person or entity (including, without limitation, Perspektives); (iii) Perspektives has the full right to provide the Client with the assignments and rights provided for herein; (iv) Perspektives shall comply with all applicable laws and Client safety rules in the course of performing the Services and (v) if Perspektives’s work requires a license, Perspektives has obtained that license and the license is in full force and effect.

## 7. Refund Policy

Services available for refunds are described here (“Refund Policy”). For products and services eligible for a refund, you may request a refund within forty five (30) days of payment ("Refund Period"). No refunds requested after the Refund Period. 

## 8. Notice

All notices under this Agreement shall be in writing, and shall be deemed given when personally delivered, sent by confirmed telecopy or other electronic means, or three (3) days after being sent by mail to the address of the party to be noticed as set forth herein or such other address as such party last provided to the other by written notice.

## 9. Termination

Each party may terminate this Agreement at any time with a thirty (30) days notice period.

## 10. Disclaimer

Client acknowledges and agrees that the use and reliance upon the Perspektives service and any third party content and services received or accessed thereby is at their sole risk and discretion. Perspektives makes no warranty that (i) the Perspektives service will meet your requirements; (ii) the Perspektives services will be uninterrupted, accurate, reliable, timely secure or error free; (iii) the quality of any products, services, information or other material accessed or obtained by you through the Perspektives service; (iv) any errors in the Perspektives service or third party content will be corrected.

## 11. Limitation of Liability

In no event shall Perspektives, its officers, directors, employees or agents, be liable to you for any direct, indirect, incidental, special, punitive, or consequential damages whatsoever resulting from or in connection to any errors, mistakes, or inaccuracies of content, personal injury or property damage, of any nature whatsoever, resulting from your access to and use of this Website,  any interruption or cessation of transmission to or from Perspektives, any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of the Service, and/or your use or inability to use the Service. Perspektives shall not be liable under any circumstance for any claim that exceeds the value of the funds you have paid to Perspektives for the use of the Service.

Perspektives has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Perspektives shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.

## 12. Client Reference Authorization

Client grants permission to Perspektives to use the Client’s company name and logo in a published listing of Perspektives customers for use in Perspektives
marketing materials and on Perspektives’ web site. Client grants Perspektives permission to provide any necessary Client related information to a relevant third party, including but not limited to printing companies, videographers and/or other agencies, for the sole purpose creating marketing materials and awareness. Client also gives permission to Perspektives to distribute materials referenced above, in electronic or hardcopy form, in whole or part, without fees or additional permissions, in internal and external press and marketing activities such as, but not limited to, presentations, proposals, papers and on the web. 

## 13. Changes

We reserve the right, at our sole discretion, to modify or replace this Agreement at any time. If a revision is material we will try to provide at least 30 (change this) days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

## 14. Applicable Law
Usage of the Service and this Agreement of Service are governed by the laws of Finland. The effective date of this Agreement of Service is: **${month} 1, ${date.getFullYear()}**

If you have any questions about this Agreement, please contact us please contact us at [hi@perspektives.net](mailto:hi@perspektives.net).
    `);

export default class Page extends React.Component {
  render() {
    return (
      <Section>
        <Logo src="/logo-cube-padded.svg" alt="Perspektives" />
        <img
          style={{ width: 110 }}
          src="/logo-caption.svg"
          alt="Perspektives"
        />
        <h1>Terms and Conditions Agreement</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <h2>
          <a href="/">&larr; Go back</a>
        </h2>
      </Section>
    );
  }
}

const TermsModal = props => (
  <Modal
    style={{ top: 20, maxWidth: 500 }}
    width={null}
    title="Terms and Conditions Agreement"
    visible={props.visible}
    onOk={props.handleOk}
    onCancel={props.handleOk}
    footer={[
      <Button onClick={props.handleOk} key="0">
        OK
      </Button>
    ]}
  >
    <div dangerouslySetInnerHTML={{ __html: html }} />
  </Modal>
);

export { TermsModal };
