import React from "react";
import ReactDOM from "react-dom";

import LandingPage from "./LandingPage";
import CookieConsent from "./CookieConsent";
import SignUpModal from "./SignUpModal";
import ThankYouModal from "./ThankYouModal";

import { sendOrder } from "../api";
import { logCta, logClick } from "../tracking";

import { TermsModal } from "../Terms";

class Main extends React.Component {
  signupForm = null; //React.createRef();

  state = {
    showSignUp: false,
    showThankYou: false,
    showTerms: false,
    loading: false
  };

  onSignUpClick = e => {
    e.preventDefault();
  };

  showModal = () => {
    this.setState({
      showSignUp: true
    });
  };

  handleSignUp = e => {
    console.log("signup", e);
    this.setState({
      showSignUp: false
    });
  };

  handleCancel = e => {
    this.setState({
      showSignUp: false
    });
  };

  handleCloseThankYou = e =>
    this.setState({
      showThankYou: false
    });

  onCTAClick = e => {
    logClick(e && e.target);
    this.setState({ showSignUp: true });
  };

  onPackageClick = (e, currentPackage) => {
    logClick(e && e.target);
    try {
      this.signupForm.selectPackage(currentPackage);
      this.setState({ showSignUp: true });
    } catch (err) {
      console.error(err);
    }
  };

  onShowTermsClick = e => {
    e.preventDefault();
    this.setState({ showTerms: true });
  };

  onSubmit = async data => {
    console.log("data", data);
    this.setState({ loading: true });
    const { name, email, pkg } = data;
    try {
      await sendOrder({
        email,
        name,
        subject: "Perspektives.net signup - FIN",
        message: `Package ${data.option}\n\n${JSON.stringify(data, 0, 2)}`
      });
    } catch (err) {
      console.error("err", err);
    }
    logCta(pkg.price);
    this.setState({ loading: false, showSignUp: false, showThankYou: true });
  };

  render() {
    const { showSignUp, showThankYou, loading } = this.state;
    return (
      <div id="App">
        <LandingPage
          onCTAClick={this.onCTAClick}
          onPackageClick={this.onPackageClick}
        />
        <CookieConsent />

        <SignUpModal
          getRef={ref => (this.signupForm = ref)}
          visible={showSignUp}
          loading={loading}
          handleOk={this.handleSignUp}
          handleCancel={this.handleCancel}
          onShowTermsClick={this.onShowTermsClick}
          onSubmit={this.onSubmit}
        />
        <ThankYouModal
          visible={showThankYou}
          handleOk={this.handleCloseThankYou}
        />
        <TermsModal
          visible={this.state.showTerms}
          handleOk={() => this.setState({ showTerms: false })}
        />
      </div>
    );
  }
}

export default Main;
