import React from "react";
import { Pkg, BulletList } from "../components";

const packages = {
  online: {
    name: "Etätyöpaja",
    price: 297,
    content: [
      <Pkg style={{ order: 0 }} key="p11">
        <h3>Etätyöpaja</h3>
      </Pkg>,
      <Pkg style={{ order: 1 }} key="p12">
        <p>
          <b>Optimoi olemassa olevia yritysprosesseja.</b>
          <br />
          Sopivin paketti jopa 10 hengen yritykselle jolla on kipukohtia yhdessä
          ydinprosesseista.
        </p>
      </Pkg>,
      <Pkg style={{ order: 2 }} key="p13">
        <div className="price-tag">
          <span className="price-old">970&euro;</span>{" "}
          <span className="price">297&euro;</span>
          <span className="label grey">Rajoitettu tarjous!</span>
        </div>
      </Pkg>,
      <Pkg style={{ order: 3 }} key="p14">
        <BulletList>
          <li>
            Helppo ja yksinkertainen arviointiohjeistus tunnistamaan
            prioriteetit ja määrittelemään tavoitteet.
          </li>
          <li>Tapauksen arviointi</li>
          <li>1 tunnin ohjattu löytöpaja</li>
          <li>Minimissään 2 suunnitelmaa ratkaisulle</li>
          <li>
            Yksityiskohtaiset eritelmät vaatimuksista jokaiselle ratkaisulle
          </li>
          <li>
            Käytettävät suunnitelmat ratkaisujen kehittämiselle ja
            julkaisemiselle.
          </li>
        </BulletList>
      </Pkg>
    ]
  },
  oneday: {
    name: "Yhden päivän tutkimuspaja",
    price: 1297,
    content: [
      <Pkg style={{ order: 0 }} key="p21">
        <h3>Yhden päivän tutkimuspaja</h3>
      </Pkg>,
      <Pkg style={{ order: 1 }} key="p22">
        <p>
          <b>Suunnitelma omalle ohjelmistolle</b>
          <br />
          Ideaali 10+ kokoiselle yritykselle jos tahtoo automatisoida tai
          integroida muutamia kriittisiä yritysprosesseja.
        </p>
      </Pkg>,
      <Pkg style={{ order: 2 }} key="p23">
        <div className="price-tag">
          <span className="price-old">4250&euro;</span>{" "}
          <span className="price">1297&euro;</span>
          <span className="label">Paras hinta!</span>
        </div>
      </Pkg>,
      <Pkg style={{ order: 3 }} key="p24">
        <BulletList>
          <li>
            Helppo ja yksinkertainen arviointiohjeistus tunnistamaan
            prioriteetit ja määrittelemään tavoitteet.
          </li>
          <li>Tapauksen arviointiraportti</li>
          <li>1 tunnin tapauksen arviointi puhelimitse</li>
          <li>1 päivän tutkimuspaja paikan päällä</li>
          <li>Henkilöstön haastatteluja</li>
          <li>1-2 tunnin ohjattu suunnitelutyöpaja</li>
          <li>Minimissään 3 suunnitelmaa ratkaisulle</li>
          <li>
            Yksityiskohtaiset eritelmät vaatimuksista jokaiselle ratkaisulle
          </li>
          <li>1 prototyypin etätestausessio</li>
          <li>
            Käytettävät suunnitelmat ratkaisujen kehittämiselle ja
            julkaisemiselle.
          </li>
        </BulletList>
      </Pkg>
    ]
  },
  oneweek: {
    name: "Yhden viikon Design Sprint",
    price: 4597,
    content: [
      <Pkg style={{ order: 0 }} key="p31">
        <h3>Yhden viikon Design Sprint</h3>
      </Pkg>,
      <Pkg style={{ order: 1 }} key="p32">
        <p>
          <b>Suunnitelma omalle ohjelmistolle tai palvelulle</b>
          <br />
          Oletko luomassa uutta digitaalista tuotetta olemassa oleville
          asiakkaille vai alustaa kumppaneillesi? Pääset parhaiten alkuun tällä
          paketilla!
        </p>
      </Pkg>,
      <Pkg style={{ order: 2 }} key="p33">
        <div className="price-tag">
          <span className="price-old">9550&euro;</span>{" "}
          <span className="price">4597&euro;</span>
        </div>
      </Pkg>,
      <Pkg style={{ order: 3 }} key="p34">
        <BulletList>
          <li>
            Helppo ja yksinkertainen arviointiohjeistus tunnistamaan
            prioriteetit ja määrittelemään tavoitteet.
          </li>
          <li>Tapauksen arviointiraportti</li>
          <li>1 viikon paikanpäällinen design sprint</li>
          <li>Henkilöstön haastatteluja</li>
          <li>3x 1-2 tunnin ohjattu suunnitelutyöpaja</li>
          <li>Minimissään 3 suunnitelmaa ratkaisulle</li>
          <li>
            Yksityiskohtaiset eritelmät vaatimuksista jokaiselle ratkaisulle
          </li>
          <li>2-3 prototyypin etätestausessiota</li>
          <li>
            Käytettävät suunnitelmat ratkaisujen kehittämiselle ja
            julkaisemiselle.
          </li>
          <li>
            <span className="label">BONUS</span> Brändätty Pitch Deck jonka voit
            esittää eri asianomistajille (stakeholders) <s> arvo 3370&euro;</s>{" "}
            <b>Ilmainen!</b>
          </li>
        </BulletList>
      </Pkg>
    ]
  },
  impact: {
    name: "Digital Transformation Blueprint",
    price: 9897,
    content: [
      <Pkg style={{ order: 0 }} key="p41">
        <h3>Digitaalisen muutoksen suunnitelma</h3>
      </Pkg>,
      <Pkg style={{ order: 1 }} key="p42">
        <p>
          <span role="img" aria-label="100">
            💯
          </span>{" "}
          <b>Täysi digitaalisen muutoksen paketti.</b>
          <br />
          Valmiina ottamaan suuria askeleita digitaalisessa murroksessa?
          <br />
          {/* Get a complete audit of all your processes, including
          operations, sales and marketing.
          <br /> */}
          Sopivin vaihtoehto jopa 100 hengen yritykselle jolla on monia
          kipukohtia monissa prosesseissa
          <br />
        </p>
      </Pkg>,
      <Pkg style={{ order: 2 }} key="p43">
        <div className="price-tag">
          <span className="price-old">18900&euro;</span>{" "}
          <span className="price">9897&euro;</span>
        </div>
      </Pkg>,
      <Pkg style={{ order: 3 }} key="p44">
        <BulletList>
          <li>
            Helppo ja yksinkertainen arviointiohjeistus tunnistamaan
            prioriteetit ja määrittelemään tavoitteet
          </li>
          <li>Tapauksen arviointiraportti</li>
          <li>1 viikon paikanpäällinen design sprint</li>
          <li>Henkilöstön haastatteluja</li>
          <li>3x 1-2 tunnin ohjattu suunnitelutyöpaja</li>
          <li>
            Yksityiskohtaiset eritelmät vaatimuksista jokaiselle ratkaisulle
          </li>
          <li>Jopa 5 suunnitelmaa ratkaisulle</li>
          <li>Jopa 5 prototyypin etätestausessiota</li>
          <li>
            Käytettävät suunnitelmat ratkaisujen kehittämiselle ja
            julkaisemiselle
          </li>
          <li>
            Laaja ja eheä raportti toteutetusta suunnitelmasta ja yleiskuva
            yrityksesi digitaalisesta arkkitehtuurista
          </li>
          <li>
            <span className="label">BONUS</span> Brändätty Pitch Deck jonka voit
            esittää eri sidosryhmille (stakeholders) <s> arvo 3370&euro;</s>{" "}
            <b>Ilmainen!</b>
          </li>
          <li>
            <span className="label">BONUS</span> Palvelumuotoilun
            dokumentaatiopaketti, jossa on käyttäjäkulkukartta, sidosryhmien
            kartta ja hinta-vaikutus matriisi. (User Journey map, Stakeholder
            Map, Cost-Impact diagram)<s>arvo 4790&euro;</s> <b>Ilmainen!</b>
          </li>
        </BulletList>
      </Pkg>
    ]
  }
};

export default packages;
