import React from "react";
import {
  Hero,
  Section,
  Content,
  Headline,
  ReferenceSection,
  Reference,
  Package,
  FAQ,
  Features,
  CTA,
  Quote,
  Footer,
  Button,
  BigButton,
  Logo,
  Team,
  Email,
  ArrowBox,
  TwoColumns,
  Process,
  BulletList,
  Pkgs,
  Pkg,
  MinProjectSection,
  NotGuaranteedSection
} from "../components";

import packages from "./Packages";

function LandingPage(props) {
  return (
    <div id="App">
      <Hero>
        <div>
          <Logo src="/logo-cube-padded.svg" alt="Perspektives" />
          <br />
          <img
            style={{ width: 110 }}
            src="/logo-caption.svg"
            alt="Perspektives"
          />
          <h1>
            Helppokäyttöisiä tarpeisiisi luotuja ohjelmistoja jopa 10X
            pienemmällä budjetilla
          </h1>
          <h5>
            Kumppani digitaalisessa murroksessa pieni- ja keskikokoisille
            yrityksille.
          </h5>
          <p>
            Autamme pieni- ja keskikokoisia yrityksiä poistamaan kipukohtia
            liittyen epätehokkaisiin prosesseihin ja huonoihin ohjelmistoihin.
          </p>
          <br />
          <Button onClick={props.onCTAClick}>Aloita projektisi nyt</Button>
        </div>
        <div style={{ position: "relative" }}>
          <img src="/Laptop.svg" alt="Laptop" className="dashboard" />
          <img src="/Panel.svg" alt="deco1" className="deco1" />
          <img src="/Panel.svg" alt="deco2" className="deco2" />
        </div>
      </Hero>
      <Section>
        <img
          src="/zone-of-pain.jpg"
          alt="Zone of Pain"
          className="responsive"
          style={{
            maxWidth: "70%",
            maxHeight: 400,
            marginTop: -30,
            float: "right",
            marginLeft: 40,
            marginBottom: 40,
            marginRight: -100
          }}
        />
        <p>Liikaa sähköpostia, kansioita, ja tiedostojen jakoa...</p>

        <p>
          Sisäiset työkalut ovat vanhanaikaisia tai loistavat poissaolollaan
          eivätkä muut vaihtoehdot näytä sopivilta.
        </p>

        <p>
          Yritysdata on hajallaan eri palveluissa tai papereissa, joka
          vaikeuttaa päätösten tekemistä.
        </p>
        <div style={{ maxWidth: 700, margin: "auto", marginTop: 100 }}>
          <Headline>
            Nämä ovat tyypillisiä <b>kipukohtia</b> pieni- ja keskikokoisissa
            yrityksissä.
          </Headline>

          <p>Esimerkkejä kipukohtien ilmentymästä:</p>
          <p style={{ marginTop: 30 }}>
            <strong>1. Järjestelmien puute</strong>
          </p>

          <p>
            Yritys hukkaa suuren määrän aikaa{" "}
            <strong>yksitoikkoisiin tehtäviin</strong>, kuten monien eri
            dokumenttiversioiden ja Excel-tiedostojen lähettämiseen
            sähköpostitse ja inhimillisten virheiden takia.
          </p>
          <p style={{ marginTop: 30 }}>
            <strong>2. Tiedon puute</strong>
          </p>

          <p>
            On hankalaa seurata kehitystä ja suunnitella tulevia
            yrityspäätöksiä.
          </p>

          <p style={{ marginTop: 30 }}>
            <strong>3. Keskittymisen puute</strong>
          </p>

          <p>
            Olet jatkuvasti stressaantunut jatkuvien tulipalojen sammuttelun
            takia.
            <br />
            <br />
            Keskittyminen herpaantuu ja pitkäaikaistavoitteet unohtuvat helposti
            päivittäisten tehtävien johdosta.
          </p>

          <p>
            Suurin ongelma on että,{" "}
            <em>et voi ratkaista ongelmaa palkkaamalla lisää työntekijöitä.</em>
            <br /> <br /> Se johtaisi vain <em>yleiskustannusten nousuun</em>…
          </p>
          <br />
          <p>
            Nämä ovat tilanteita joissa digitaaliset ratkaisut auttavat ja
            helpottavat yritysprosessien automatisoinnissa.
          </p>
        </div>
        <div>
          <Quote>
            <img src="/quote-mark.svg" alt='"' />
            <br />
            <strong>
              “Digital rewards first movers and some superfast followers.”
            </strong>
            <br /> &mdash; early digital adopters enjoyed 2X the revenue growth
            of competitors.
            <br />
            <small>
              (McKinsey tutkimus 2018){" "}
              <sup>
                <a
                  target="_blank"
                  href="https://www.mckinsey.com/business-functions/digital-mckinsey/our-insights/why-digital-strategies-fail"
                >
                  1
                </a>
              </sup>
            </small>
          </Quote>

          <Quote>
            <img src="/quote-mark.svg" alt='"' />
            <br />"<b>49.3%</b> of IT decision-makers said digital helped small
            businesses compete{" "}
            <b>more effectively against larger competitors</b>."
            <br />
            <br />"<b>40.8%</b> said that smaller companies could{" "}
            <b>move more quickly</b> in taking advantage of{" "}
            <b>digital innovations</b>."
            <br />
            <small>
              (kysely SAP:lla ja IDC:ssa 2016){" "}
              <sup>
                <a href="https://www.digitalistmag.com/digital-economy/2016/03/22/why-digital-transformation-is-not-out-of-reach-for-small-businesses-04093546">
                  2
                </a>
              </sup>
            </small>
          </Quote>
          <div style={{ clear: "both" }} />
        </div>
      </Section>
      <Section>
        <ArrowBox>
          <h2>Yksinkertainen fakta...</h2>
          <p>
            Jokainen yritys on omalaatuisensa, ja se on miltei mahdotonta löytää
            yleinen softa, joka vastaisi yrityksen kaikkia tarpeita.
          </p>

          <p>
            Toisaalta, isoilla yrityksillä on suuremmat budjetit
            ohjelmistohankintoihin.
          </p>
          <p>
            <b>Se on liian suuri sitoutuminen pienemmälle yritykselle.</b>
          </p>
        </ArrowBox>
        <Headline>
          Monien digitaalisten palvelujen kehittäjien minimibudjetit ovat aika
          suurehkoja.
        </Headline>

        <p>
          {/* <img
              style={{
                position: "absolute",
                zIndex: 2,
                left: "50%",
                width: 50,
                display: "block",
                marginTop: -20
              }}
              src="./long-arrow-down.svg"
              alt="long arrow"
            />
            <img
              style={{ marginTop: 20, marginLeft: -120, maxWidth: 840 }}
              src="/prices.jpg?1"
              alt="Software Development Prices Helsinki"
            /> */}
        </p>

        <MinProjectSection>
          <p>
            <img
              className="responsive"
              src="/prices-cropped.jpg"
              alt="typical prices"
            />
            On erittäin tyypillistä, että digitaalisten projektien aloitushinta
            alkaa 10000&euro;:sta.
          </p>
          <div style={{ width: "100%", clear: "both" }} />
        </MinProjectSection>
        <NotGuaranteedSection>
          <p>
            <img
              src="/you-just-dont-know-crop.jpg"
              alt="you just don't know.."
            />
            <br />
            <br />
            Eikä kyse ole vain rahasta. Tuntuu <b>suurelta riskiltä</b>{" "}
            sijoittaa johonkin tuntemattomaan, joka ei välttämättä edes toimi...
            <br />
            <br />
            Et ole varma mikä ratkaisu toimii ja{" "}
            <em>epäröit kaikkia vaihtoehtoja...</em>
          </p>
        </NotGuaranteedSection>
      </Section>
      <Section style={{ clear: "both" }}>
        <h2
          style={{
            maxWidth: 700,
            margin: "auto",
            marginTop: 120,
            marginBottom: 60,
            fontSize: 32,
            clear: "both",
            textAlign: "center"
          }}
        >
          {/* What if there would be a way to find 
            out which solution would be the
            best fit for your company{" "} */}
          Mitä jos olisi olemassa tapa löytää juuri sopiva ratkaisu sinun
          yrityksellesi <b>sijoittamatta suurta määrää rahaa ja aikaa</b>
        </h2>
        <Content>
          <p>Tämän takia me olemme olemassa.</p>

          <img
            className="responsive"
            style={{ float: "right", marginRight: -450, marginLeft: 30 }}
            src="/writing-codes.jpg"
            alt="writing codes"
          />

          <p>
            Olemme <strong>Perspektives</strong> &mdash; tiimi suunnittelijoita
            ja ohjelmistokehittäjiä keskittyneitä luomaan digitaalisia
            ratkaisuja, kokeilemaan ohjelmistokonsepteja ja innovaatiokokeiluja.
          </p>

          <p style={{ margin: 30 }}>
            Olemme luoneet oman{" "}
            <strong>
              toimivan ja yksinkertaisen suunnitteluajattelumetodologian.
            </strong>
            <br />
          </p>
          <div style={{ marginTop: 20 }}>
            1. arvioi prosessit nopeasti
            <br />
            2. löydä ongelman juuri ja <br />
            3. <strong>keksi kustannustehokkain ratkaisu</strong>.
          </div>

          <p>Se on kattava, yksityiskohtainen ja tehokas.</p>
          <div style={{ maxWidth: 500 }}>
            <p>
              Saat tarkat ja oleelliset tiedot joita tarvitset päätösten tekoon.
            </p>
          </div>
          <p>Ja parasta on:</p>

          <p style={{ margin: 30 }}>
            <strong>Se ei vaadi suurta ajan ja rahan sijoittamista.</strong>
          </p>

          <p>
            Sinun ei tarvitse tuhlata aikaa monissa tapaamisissa, joissa
            keskustellaan mitä pitää tehdä ja takerrutaan yksityiskohtiin.
          </p>

          <p>
            Se on jopa <strong>5x - 10x kertaa halvempaa</strong> kuin yleiset
            IT konsultointihinnat.
          </p>

          <p>
            Hintamme <strong>alkavat alle 20%</strong> alan keskivertohinnoista.
          </p>
          <p style={{ marginTop: 60 }}>
            <Button onClick={props.onCTAClick}>
              Aloita projektisi parissa nyt
              <span>
                15-30 arkipäivän toimitus. Nopeaa &amp; yksinkertaista.
              </span>
            </Button>
          </p>
        </Content>
      </Section>

      <Section>
        <Content>
          <h2 id="how-can-it-be-so-cheap">Miten se voi olla näin halpaa?</h2>

          <p>
            Vietämme <strong>0%</strong> ajastasi turhaan touhuiluun ja
            byrokratiaan.
          </p>

          <p>
            Prosessimme on <strong>erittäin yksinkertainen</strong> ja{" "}
            <strong>avoin</strong>.
          </p>

          <p>Näin me toimimme:</p>

          <Process>
            <div className="step">
              <div className="num">1</div>
              <p>
                <strong>Valitse pakettisi</strong>
                <br /> Valitse sopivin pakettii tarpeisiisi
              </p>
            </div>
            <div className="step">
              <div className="num">2</div>
              <p>
                <strong>Vastaa arviointikyselyyn</strong>
                <br />
                Tämä on yksinkertainen lista, joka auttaa sinua määrittelemään
                kaikki tarvittavat yksityiskohdat, kuten esimerkiksi tavoitteet
                ja vaatimuksesi itse ratkaisulta.
              </p>
            </div>
            <div className="step">
              <div className="num">3</div>
              <p>
                <strong>Ei enää turhia tapaamisia</strong>
                <br />
                Riippuen tapauksestasi ja vaatimuksistasi jäsentelemme
                työprosessimme niin, ettei sinun tarvitse tuhlata yhtään aikaa
                ja me keräämme kaikki vaatimukset ratkaisulle.
              </p>
            </div>
            <div className="step">
              <div className="num">4</div>
              <p>
                <strong>Käytämme aikaa yrityksesi ymmärtämiseen.</strong>
                <br />
                Tutkimme tapaustasi, käymme kaiken tiedon läpi ja suunnittelemme
                sopivimmat vaihtoehdot yrityksesi digiratkaisulle.
              </p>
            </div>
            <div className="step">
              <div className="num">5</div>
              <p>
                <strong>Varmistamme, että sinulla on oikea digiratkaisu</strong>
                <br />
                Valmistelemme ja lähetämme sinulle täyden tarkastusraportin
                tapauksestasi yksityiskohtaisilla kuvauksilla ja kattavan listan
                digiratkaisuista.
              </p>
            </div>
            <div className="step">
              <div className="num">6</div>
              <p>
                <strong>Ei stressiä eikä arvaamattomuutta.</strong>
                <br />
                Nyt sinulla on tilanne täydessä hallinnassa, sinulla on kaikki
                vaihtoedot pöydällä ja voit valita oikean ratkaisun joka otetaan
                työn alle.
              </p>
            </div>
          </Process>
          <div
            style={{
              position: "relative",
              marginTop: 200,
              marginBottom: 200
            }}
          >
            <img
              style={{
                position: "absolute",
                maxWidth: 800,
                marginLeft: -50,
                marginTop: -200,
                zIndex: 0,
                opacity: 0.3
              }}
              src="/mock-dashboard.jpg"
              alt="mock dashboard"
            />
            <Button
              onClick={props.onCTAClick}
              style={{
                position: "relative",
                display: "block",
                margin: "auto",
                zIndex: 2
              }}
            >
              Tilaa tarkastusraporttisi nyt
              <span>Avaimet käteen. Yksinkertaista &amp; helppoa.</span>
            </Button>
          </div>
        </Content>
      </Section>

      <Section>
        <Headline id="references" style={{ marginTop: 10 }}>
          Meistä on sanottua:
        </Headline>
        <ReferenceSection>
          <Reference>
            “Very knowledgeable and experienced team with whom communication was
            good.
            <br /> The agreed issues were kept and the guidance and reporting on
            the work was clear.”
            <br />
            <br /> Antti-Jussi Mäki
            <br /> CEO, Rescomms Oy
          </Reference>

          <Reference>
            “I have had the pleasure to participate and follow a few projects by
            them. Not only do they architect and deliver quality software, but
            they also make accurate estimates on the work required and conduct
            state-of-art lean development.”
            <br />
            <br /> Olavi Toivainen
            <br /> Managing Director at Specsign Production Ltd
          </Reference>

          <Reference>
            “They are very professional team, really nice to work with!"
            <br />
            <br /> Kaisa Kromhof
            <br /> Legal Entrepreneur
          </Reference>

          <Reference>
            “Working together has been easy and effective. Their knowledge is
            incredibly valuable and products they deliver are always as high
            quality as promised.”
            <br />
            <br /> Anni Kalkainen
            <br /> Project Manager, 020202 Palvelut Oy
          </Reference>

          <Reference>
            “We are very happy with the very high level of professionalism. In
            addition to this the communication with them is effortless and
            inspiring. Finding a reliable and efficient software development
            partner is extremely difficult. We have been very fortunate.
            <br /> My strongest recommendations.”
            <br />
            <br /> Christian Alopaeus
            <br /> CEO, Ilona IT Oy
          </Reference>

          <Reference>
            “Great software developers who get things done.
            <br /> As an experienced software developer and entrepreneur I know
            how hard it is to find capable engineers who know exactly what to do
            and focus on getting things done. These guys are able to contribute
            much more than just code - from scalable architecture to hard-core
            R&amp;D. Was a great pleasure to work with them on several
            projects!”
            <br />
            <br /> Mikko Lehmuskoski
            <br /> CEO at Pähkinänsärkijä
            <br /> ex Innovation &amp; Ventures at Sanoma Digital Finland
          </Reference>

          <Reference>
            “Bold ideas. Amazing execution. Mad skills.
            <br /> It has been a pleasure to work with the Perspektives guys in
            several projects. They are very energetic, knowledgeable and down to
            earth. I am genuinely convinced that they can implement any idea you
            can come up with!”
            <br />
            <br /> Kasper Suomalainen
            <br /> ex COO, Director of Community at Startup Sauna
            <br /> Associate at Superhero Capital
          </Reference>

          <Reference>
            “A rare entrepreneurial attitude combined with tech and prototyping.
            Definitely recommendable!” 😎✌🏿”
            <br />
            <br /> Riku Aakala, Entrepreneur
            <br /> CEO Senentry Oy
          </Reference>
        </ReferenceSection>
      </Section>

      <Section style={{ maxWidth: 1730 }}>
        <Content style={{ marginTop: 0 }}>
          <Headline id="discover-workshop-packages">Työpajapaketit</Headline>

          <p>
            Aloita projektisi yhdellä meidän <strong>löytöpaketilla</strong>{" "}
            jotka loimme, jotta voit ottaa ensimmäisen askeleen helposti.
          </p>

          <p>Mikä vaihtoehto on sopivin yrityksellesi?</p>

          {/* <p>All our plans include:</p>
          <BulletList>
            <li>
              Easy and simple case assessment guide to identify the
              probpriorities define goals
            </li>
            <li>Case specification report</li>
            <li>1-hour guided online discovery workshop</li>
            <li>Minimum 2 solution designs</li>
            <li>Detailed requirements specification for each solution</li>
            <li>Actionable go-to plan to develop and deploy every solution</li>
          </BulletList> */}
        </Content>
        <br />
        <br />
        <Pkgs>
          {Object.keys(packages).map(key => {
            const pkg = packages[key];
            return pkg.content.concat(
              <Pkg style={{ order: pkg.content.length }} key={key}>
                <Button onClick={e => props.onPackageClick(e, key)}>
                  Tilaa nyt <b>&rarr;</b>
                  <span>Aloita arviointikysely</span>
                </Button>
              </Pkg>
            );
          })}
        </Pkgs>
      </Section>

      <Section>
        <Headline id="we-guarantee">Takaamme</Headline>

        <p>
          <img
            className="responsive"
            src="/guarantee.svg"
            alt="guarantee"
            style={{ float: "left", verticalAlign: "top" }}
          />
          Perspektives hoitaa projektisi{" "}
          <em>ammattitaidolla ja tehokkuudella</em>, vapauttaen aikaasi
          tärkeämpiin asioihin.
        </p>

        <p>
          Takaamme riskittömän{" "}
          <strong>60-päivän 100% rahat takaisin takuun</strong>.
        </p>

        <ol>
          <li style={{ margin: 20 }}>
            Vietät vähemmän aikaa huolehtien teknologiasta ja kohdistat enemmän
            aikaa asiakkaillesi, tuotteellesi ja strategisiin tavoitteisiin.
          </li>
          <li style={{ margin: 20 }}>
            Hoidamme kommunikoinnin niin, että kaikki ovat samalla sivulla.
          </li>
          <li style={{ margin: 20 }}>
            Saat vastauksen aina 24 tunnin sisään, jottet tuhlaa aikaa
            vastausten odottamiseen.
          </li>
        </ol>
        <BigButton onClick={props.onCTAClick}>
          Aloita projektisi parissa nyt
          <span>100% riskitöntä.</span>
        </BigButton>
      </Section>

      {/* <Section>
          <h1 id="heres-who-should-work-with-us-🙋&zwj;♂️">
            Here’s Who Should Work With Us 🙋&zwj;♂️
          </h1>

          <p>
            If you’re a small or medium company that is looking to automate its
            processes or create a new digital service:
          </p>

          <p>OR, if any of these apply to you:</p>

          <ul>
            <li>You AGREE it’s better to MINIMIZE RISK and MAXIMIZE RESULTS</li>
            <li>
              You AGREE that you want a reliable &amp; trustworthy production
              company who actually cares about your success
            </li>
            <li>
              You AGREE that spending many 100000€ on an unproved and not tested
              project is not the smartest move
            </li>
            <li>
              You AGREE that you’re better off spending a small fraction of your
              budget to find the right solution and validate it, rather than
              putting the whole budget into a long-term expensive commitment
            </li>
            <li>
              You AGREE you should trust the expertise of professionals who are
              not going to waste your time in countless meetings
            </li>
            <li>
              You AGREE that unreliable freelancers and expensive consulting
              agencies are complicated &amp; overrated.
            </li>
          </ul>
        </Section> */}

      <Section>
        <h1 id="frequently-asked-questions">Usein kysytyt kysymykset</h1>

        <FAQ>
          <li>
            <b>Mitä saan?</b>
            <ul>
              <li>
                suunnittelemme ja luomme kokonaisen suunnitelman digiratkaisulle
                jota voit käyttää joko yritysprosessien automaatioon tai luoda
                digitaalisen palvelun asiakkaillesi.
              </li>
            </ul>
          </li>
          <li>
            <b>Kuinka se toimii??</b>
            <ul>
              <li>Valitset sopivimman paketin täällä</li>
              <li>
                Käyt yksinkertaisen arviointikyselyn, joka auttaa jäsentelemään
                vaatimuksesi.
              </li>
              <li>
                Suoritamme muutaman sisäisen ja ulkoisen työpajan ja
                suunnittelemme sopivat ratkaisut sinulle.
              </li>
              <li>
                Saat koko paketin suunnitelman ja ohjeistuksen kuinka toteuttaa
                ratkaisun.
              </li>
            </ul>
            <em>Kun kysyntä on suurta, toimitus voi kestää jopa 30 päivää.</em>
          </li>
          <li>
            <b>Paljonko se maksaa?</b>
            <ul>
              <li>
                Tarjoamme kiinteän hinnan paketteja eri kokoisille yrityksille
                alkaen 297€.
              </li>
            </ul>
            <em>
              Hinnat voivat muuttua. Lisäkustannuksia voi tulla tilauksen
              jälkeen, jos on tiettyjä erityisvaatimuksia kehityksen aikana.
            </em>
          </li>
          <li>
            <b>
              Suunnitteletteko käyttöliittymiä / käyttäjäkokemuksia (UI/UX)
              ratkaisuillenne?
            </b>
            <ul>
              <li>
                Kyllä! osana ratkaisun suunnitelmaa. Voit käyttää
                käyttöliittymän mallia osana vaatimuksia, kun toteutat
                suunnitelmaa.
              </li>
            </ul>
          </li>
          <li>
            <b>Mitä jos minulla on monimutkainen tapaus?</b>
            <ul>
              <li>
                Ei huolta! Me järjestämme designtyöpajan ongelman erittelyyn ja
                löydämme kustannustehokkaimman ratkaisun.
              </li>
            </ul>
          </li>
          <li>
            <b>Voitteko matkustaa meidän sijainnillemme?</b>
            <ul>
              <li>Kyllä, matkustuslisän kera.</li>
            </ul>
          </li>
          <li>
            <b>Tarjoatteko alennuksia?</b>
            <ul>
              <li>
                Tarjoamme alennuksia pitkäaikaisasiakkaille tai kumppanuuksien
                ja promokampanjoiden kautta.
              </li>
            </ul>
          </li>
          <li>
            <b>Onko rahat takaisin takuuta?</b>
            <ul>
              <li>
                Kyllä, meillä on 60 päivän rahat takaisin takuu ilman
                kysymyksiä.
              </li>
            </ul>
          </li>
          <li>
            <b>Onko minulla omistusoikeus ratkaisun suunnitelmaan?</b>
            <ul>
              <li>
                Kyllä, ehdottomasti! Omistat 100% luoduista suunnitelmista.
              </li>
            </ul>
          </li>
          <li>
            <b>Minulla on lisää kysyttävää!</b>
            <ul>
              <li>
                Vastaamme mielellämme kaikkiin kysymyksiin, laita meille
                sähköpostia osoitteeseen{" "}
                <a href="mailto:hi@perspektives.net">hi@perspektives.net</a>
              </li>
              <li>Vastaamme aina 24 tunnin sisään.</li>
            </ul>
          </li>
        </FAQ>
        <BigButton onClick={props.onCTAClick}>
          Aloita projektisi parissa
          <span>Se vie alle 10 minuuttia</span>
        </BigButton>
      </Section>

      <Team>
        <Content>
          <Headline id="team">Tiimi</Headline>

          <h2 id="we-really-care">
            Perspektivesillä välitämme yrityksestäsi kuin se olisi meidän oma.
          </h2>

          <p>
            Meidän vastuullamme on luoda paras asiakaskokemus toimittamalla
            laadukkaita teknologiaratkaisuja jotka ylittävät odotuksesi ja
            auttavat yritystäsi kasvamaan.
            <br /> <br />
            Luotettavuus on kaikki kaikessa meille ja pyrimme luomaan
            pitkäkestoisia suhteita asiakkaidemme kanssa.
          </p>
        </Content>
        <br />
        <br />
        <div className="people">
          <div className="col">
            <img src="/assets/photos/04-adrian.jpg" />
            <label>Adrian Wilcock</label>
            <p>
              Design
              <br />
              <Email>adrian@perspektives.net</Email>
            </p>
          </div>
          <div className="col">
            <img src="/assets/photos/04-niko.jpg" />
            <label>Niko Jääskeläinen</label>
            <p>
              Operations
              <br />
              <Email>niko@perspektives.net</Email>
            </p>
          </div>

          <div className="col">
            <img src="/assets/photos/04-altana.jpg" />
            <label>Altana Getselova</label>
            <p>
              Communications
              <br />
              <Email>altana@perspektives.net</Email>
            </p>
          </div>

          <div className="col">
            <img src="/assets/photos/04-vlad.jpg" />
            <label>Vlad Bondarenko</label>
            <p>
              Engineering
              <br />
              <Email>vlad@perspektives.net</Email>
            </p>
          </div>
        </div>
        <BigButton onClick={props.onCTAClick}>
          Aloita projektisi parissa
          <span>Se vie alle 10 minuuttia</span>
        </BigButton>
      </Team>

      <Footer>
        <div>
          <div>
            <label>YHTEYSTIEDOT</label>
            <br />
            <br />
            <a href="mailto:hi@perspektives.net">hi@perspektives.net</a>
            <br />
            <a href="tel:+358 942 550 354">+358 942 550 354</a>
            <br />
            <br />
            <a
              href="https://twitter.com/prspktvs"
              target="_blank"
              className="twitter"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 72 72"
                width="36"
                height="36"
              >
                <path fill="none" d="M0 0h72v72H0z" />
                <path
                  className="icon"
                  fill="#e370e2"
                  d="M68.812 15.14c-2.348 1.04-4.87 1.744-7.52 2.06 2.704-1.62 4.78-4.186 5.757-7.243-2.53 1.5-5.33 2.592-8.314 3.176C56.35 10.59 52.948 9 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396c-1.125 1.936-1.77 4.184-1.77 6.58 0 4.543 2.312 8.552 5.824 10.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09-4.482 3.51-10.13 5.605-16.26 5.605-1.055 0-2.096-.06-3.122-.184 5.794 3.717 12.676 5.882 20.067 5.882 24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693 2.558-1.847 4.778-4.15 6.532-6.774z"
                />
              </svg>
              Twitter
            </a>
            <a
              href="https://www.instagram.com/prspktvs/"
              target="_blank"
              className="instagram"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="36"
                height="36"
              >
                <path
                  style={{ fill: "#e370e2" }}
                  d="M256,49.471c67.266,0,75.233.257,101.8,1.469,24.562,1.121,37.9,5.224,46.778,8.674a78.052,78.052,0,0,1,28.966,18.845,78.052,78.052,0,0,1,18.845,28.966c3.45,8.877,7.554,22.216,8.674,46.778,1.212,26.565,1.469,34.532,1.469,101.8s-0.257,75.233-1.469,101.8c-1.121,24.562-5.225,37.9-8.674,46.778a83.427,83.427,0,0,1-47.811,47.811c-8.877,3.45-22.216,7.554-46.778,8.674-26.56,1.212-34.527,1.469-101.8,1.469s-75.237-.257-101.8-1.469c-24.562-1.121-37.9-5.225-46.778-8.674a78.051,78.051,0,0,1-28.966-18.845,78.053,78.053,0,0,1-18.845-28.966c-3.45-8.877-7.554-22.216-8.674-46.778-1.212-26.564-1.469-34.532-1.469-101.8s0.257-75.233,1.469-101.8c1.121-24.562,5.224-37.9,8.674-46.778A78.052,78.052,0,0,1,78.458,78.458a78.053,78.053,0,0,1,28.966-18.845c8.877-3.45,22.216-7.554,46.778-8.674,26.565-1.212,34.532-1.469,101.8-1.469m0-45.391c-68.418,0-77,.29-103.866,1.516-26.815,1.224-45.127,5.482-61.151,11.71a123.488,123.488,0,0,0-44.62,29.057A123.488,123.488,0,0,0,17.3,90.982C11.077,107.007,6.819,125.319,5.6,152.134,4.369,179,4.079,187.582,4.079,256S4.369,333,5.6,359.866c1.224,26.815,5.482,45.127,11.71,61.151a123.489,123.489,0,0,0,29.057,44.62,123.486,123.486,0,0,0,44.62,29.057c16.025,6.228,34.337,10.486,61.151,11.71,26.87,1.226,35.449,1.516,103.866,1.516s77-.29,103.866-1.516c26.815-1.224,45.127-5.482,61.151-11.71a128.817,128.817,0,0,0,73.677-73.677c6.228-16.025,10.486-34.337,11.71-61.151,1.226-26.87,1.516-35.449,1.516-103.866s-0.29-77-1.516-103.866c-1.224-26.815-5.482-45.127-11.71-61.151a123.486,123.486,0,0,0-29.057-44.62A123.487,123.487,0,0,0,421.018,17.3C404.993,11.077,386.681,6.819,359.866,5.6,333,4.369,324.418,4.079,256,4.079h0Z"
                />
                <path
                  style={{ fill: "#e370e2" }}
                  d="M256,126.635A129.365,129.365,0,1,0,385.365,256,129.365,129.365,0,0,0,256,126.635Zm0,213.338A83.973,83.973,0,1,1,339.974,256,83.974,83.974,0,0,1,256,339.973Z"
                />
                <circle
                  style={{ fill: "#e370e2" }}
                  cx="390.476"
                  cy="121.524"
                  r="30.23"
                />
              </svg>
              Instagram
            </a>
            <br />
            <a href="/privacy-policy" target="_blank">
              Tietosuojakäytäntö
            </a>
            <br />
            <a href="/terms" target="_blank">
              Käyttöehdot
            </a>
            <br />
          </div>
          <div>
            Kampinkuja 2 <br />
            00100, Helsinki
            <br />
            2982068-6
            <br />
            <br />
            <br />
            <svg
              viewBox="0 0 84 84"
              xmlns="http://www.w3.org/2000/svg"
              width="128"
            >
              <g transform="matrix(.79599 0 0 .79599 11.192 9.9948)">
                <path
                  d="m1.8037 61.858-0.63051 1.361c-0.53018-0.2456-0.86948-0.7767-0.86949-1.361zm37.149-60.218-0.6193-1.3662c0.3938-0.17848 0.8454-0.17839 1.2391 2.36e-4zm-35.594 16.134-0.62057-1.3656 0.0013-6e-4zm-1.5559 1.3346h-1.5c0-0.29 0.084037-0.5737 0.24194-0.8169zm35.599 59.241-0.6263 1.363-0.0042-0.0019zm1.5508-1.1614c0.8285 0 1.5 0.6716 1.5 1.5s-0.6715 1.5-1.5 1.5zm35.594-59.398 0.6197-1.3659 0.0028 0.0012zm2.8125 0.5185c0.4512 0.6948 0.2537 1.6238-0.4411 2.075-0.6948 0.4511-1.6238 0.2536-2.075-0.4412zm-37.787-15.302-35.594 16.134-1.2385-2.7324 35.594-16.134zm-35.592 16.133c-0.3813 0.1733-0.69875 0.4476-0.91838 0.7859l-2.5161-1.6338c0.53518-0.8242 1.2977-1.4763 2.1933-1.8833zm-1.5459 41.358 35.599 16.491-1.261 2.7221-35.599-16.491zm35.594 16.489c0.2879 0.1324 0.6037 0.2016 0.9244 0.2016v3c-0.7514 0-1.4947-0.162-2.1771-0.4756zm1.5443-76.712 35.594 16.15-1.2395 2.7319-35.594-16.15zm35.596 16.151c0.8942 0.4079 1.6554 1.0598 2.19 1.8832l-2.5161 1.6338c-0.2199-0.3387-0.5375-0.6135-0.919-0.7876zm-74.866 45.432-3.3e-5 -42.75h3l3e-5 42.75z"
                  fill="#fff"
                />
                <path
                  d="m40.46 65.97 10.916 6.6427m2.3252-39.499 21.733 14.14m-34.974 4.864 23.693 15.181m2.0588-40.299 9.4359 6.1144m-35.187 5.9567 35.749 21.793"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
              </g>
            </svg>
            <br />
            Perspektives Digital {new Date().getFullYear()}
          </div>
        </div>
      </Footer>
    </div>
  );
}

export default LandingPage;
