const sendOrder = async opts => {
  console.log("opts", opts);
  const res = await fetch("https://us-central1-p11-form.cloudfunctions.net/sendMail", {
    method: "POST",
    body: JSON.stringify(opts),
    headers: {
      "Content-type": "application/json"
    }
  });
  const data = await res.json();
  console.log("email sent", data);
  return data;
};

export { sendOrder };
