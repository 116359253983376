import LogRocket from "logrocket";

function logCta(price) {
  console.log("price", price);
  if (document.querySelectorAll("#log-cta").length) return;
  var img = document.createElement("img");
  img.id = "log-cta";
  img.src =
    "https://px.ads.linkedin.com/collect/?pid=1290276&conversionId=1475244&fmt=gif";
  document.body.appendChild(img);
  if (window.fbq) {
    window.fbq("track", "Purchase", { value: price, currency: "EUR" });
  }
  if (window.gtag) {
    window.gtag("event", "purchase", { value: price, currency: "EUR" });
  }
}

function logClick(el) {
  if (window.fbq) {
    window.fbq("track", "click", {
      content_category: "Button",
      content_name: el ? el.innerText : "<no label>",
      value: 1
    });
  }
  if (window.gtag) {
    window.gtag("event", "click", {
      event_category: "Button",
      event_label: el ? el.innerText : "<no label>",
      value: 1
    });
  }
}

function facebookPixel() {
  (function(f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );
  window.fbq("init", "2360787940826157");
  window.fbq("track", "PageView");
}

function googleTracker() {
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.src = "https://www.googletagmanager.com/gtag/js?id=UA-148768162-1";
  document.head.appendChild(s);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    window.dataLayer.push(arguments);
  };
  window.gtag("js", new Date());
  window.gtag("config", "UA-148768162-1");
}

function linkedInTag() {
  window._linkedin_partner_id = "1290276";
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(window._linkedin_partner_id);

  (function() {
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);
  })();
}

function initTracking() {
  if (
    window.location.host === "perspektives.net" &&
    window.location.search.indexOf("utm_campaign") > -1
  ) {
    LogRocket.init("krxczx/perspektives");
  }
  googleTracker();
  facebookPixel();
  linkedInTag();
}

export { initTracking, logCta, logClick };
