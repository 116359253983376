import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import MessengerCustomerChat from "react-messenger-customer-chat";

import Main from "./Main";
import MainFi from "./fi/Main";
import Privacy from "./Privacy";
import Terms from "./Terms";
import { initTracking } from "./tracking";

class App extends React.Component {
  componentDidMount() {
    initTracking();
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/privacy-policy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/fi">
            <MainFi />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
        <MessengerCustomerChat
          pageId="218996402306632"
          appId=""
          themeColor="#ee1696"
        />
      </Router>
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
