import React from "react";
import { Pkg, BulletList } from "./components";

const packages = {
  online: {
    name: "Online Audit",
    price: 297,
    content: [
      <Pkg style={{ order: 0 }} key="p11">
        <h3>Online Audit</h3>
      </Pkg>,
      <Pkg style={{ order: 1 }} key="p12">
        <p>
          <b>Optimize existing business process.</b>
          <br />
          Best fit for an up to 10 people company having painful overheads in
          one of their core processes.
        </p>
      </Pkg>,
      <Pkg style={{ order: 2 }} key="p13">
        <div className="price-tag">
          <span className="price-old">970&euro;</span>{" "}
          <span className="price">297&euro;</span>
          <span className="label grey">LIMITED OFFER!</span>
        </div>
      </Pkg>,
      <Pkg style={{ order: 3 }} key="p14">
        <BulletList>
          <li>
            Easy and simple case assessment guide to identify the priorities and
            define your goals
          </li>
          <li>Case specification report</li>
          <li>1-hour guided online discovery workshop</li>
          <li>Minimum 2 solution designs</li>
          <li>Detailed requirements specification for each solution</li>
          <li>Actionable go-to plan to develop and deploy every solution</li>
        </BulletList>
      </Pkg>
    ]
  },
  oneday: {
    name: "One Day Discovery Workshop",
    price: 1297,
    content: [
      <Pkg style={{ order: 0 }} key="p21">
        <h3>One Day Discovery Workshop</h3>
      </Pkg>,
      <Pkg style={{ order: 1 }} key="p22">
        <p>
          <b>Blueprint for your custom software.</b>
          <br />
          Ideal for a 10+ persons company looking to automate or integrate a
          couple critical processes
        </p>
      </Pkg>,
      <Pkg style={{ order: 2 }} key="p23">
        <div className="price-tag">
          <span className="price-old">4250&euro;</span>{" "}
          <span className="price">1297&euro;</span>
          <span className="label">BEST PRICE!</span>
        </div>
      </Pkg>,
      <Pkg style={{ order: 3 }} key="p24">
        <BulletList>
          <li>
            Easy and simple case assessment guide to identify the priorities and
            define your goals
          </li>
          <li>Case specification report</li>
          <li>1-hour online discovery call</li>
          <li>1-day on-site discovery audit</li>
          <li>interviews with the key staff members</li>
          <li>1-2 hour guided design workshop</li>
          <li>Minimum 3 solution designs</li>
          <li>Detailed requirements specification for each solution</li>
          <li>1 prototype testing online session</li>
          <li>Actionable go-to plan to develop and deploy every solution</li>
        </BulletList>
      </Pkg>
    ]
  },
  oneweek: {
    name: "One Week Design Sprint",
    price: 4597,
    content: [
      <Pkg style={{ order: 0 }} key="p31">
        <h3>One Week Design Sprint</h3>
      </Pkg>,
      <Pkg style={{ order: 1 }} key="p32">
        <p>
          <b>Blueprint for your new software product or service idea.</b>
          <br />
          Looking to creating a digital product for your existing customer base,
          or create an online platform for your partners? This is the best
          package to get started with!
        </p>
      </Pkg>,
      <Pkg style={{ order: 2 }} key="p33">
        <div className="price-tag">
          <span className="price-old">9550&euro;</span>{" "}
          <span className="price">4597&euro;</span>
        </div>
      </Pkg>,
      <Pkg style={{ order: 3 }} key="p34">
        <BulletList>
          <li>
            Easy and simple case assessment guide to identify the priorities and
            define your goals
          </li>
          <li>Case specification report</li>
          <li>1-week on-site design sprint</li>
          <li>Interviews with the key staff members</li>
          <li>3x 1-2 hour guided design workshops</li>
          <li>Minimum 3 solution designs</li>
          <li>Detailed requirements specification for each solution</li>
          <li>2-3x prototype testing sessions</li>
          <li>Actionable go-to plan to develop and deploy every solution</li>
          <li>
            <span className="label">BONUS</span> Branded Pitch Deck to present
            your project to stakeholders <s>3370&euro; value</s> <b>FREE!</b>
          </li>
        </BulletList>
      </Pkg>
    ]
  },
  impact: {
    name: "Digital Transformation Blueprint",
    price: 9897,
    content: [
      <Pkg style={{ order: 0 }} key="p41">
        <h3>Digital Transformation Blueprint</h3>
      </Pkg>,
      <Pkg style={{ order: 1 }} key="p42">
        <p>
          <span role="img" aria-label="100">
            💯
          </span>{" "}
          <b>Complete digital transformaion package.</b>
          <br />
          Ready for major steps towards your digital transformation?
          <br />
          {/* Get a complete audit of all your processes, including
          operations, sales and marketing.
          <br /> */}
          Best fit for an up to 100 people company having painful overheads in
          many proccesses
          <br />
        </p>
      </Pkg>,
      <Pkg style={{ order: 2 }} key="p43">
        <div className="price-tag">
          <span className="price-old">18900&euro;</span>{" "}
          <span className="price">9897&euro;</span>
        </div>
      </Pkg>,
      <Pkg style={{ order: 3 }} key="p44">
        <BulletList>
          <li>
            Easy and simple case assessment guide to identify the priorities and
            define your goals
          </li>
          <li>Case specification report</li>
          <li>1-week on-site design sprint</li>
          <li>Interviews with the key staff members</li>
          <li>
            Get a comprehensive report of multi-process solutions with a
            complete architecture of your digital infrastructure
          </li>
          <li>Up to 5 solution designs</li>
          <li>Up to 5 prototype testing sessions</li>
          <li>Detailed requirements specification for each solution</li>
          <li>Actionable go-to plan to develop and deploy every solution</li>
          <li>
            <span className="label">BONUS</span> Branded Pitch Deck to present
            your project to stakeholders <s>3370&euro; value</s> <b>FREE!</b>
          </li>
          <li>
            <span className="label">BONUS</span> Service Design documentation
            package including User Journey map, Stakeholder Map and Cost-Impact
            diagram <s>4790&euro; value</s> <b>FREE!</b>
          </li>
        </BulletList>
      </Pkg>
    ]
  }
};

export default packages;
