import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import color from "tinycolor2";

const Hero = styled("div")`
  margin: auto;
  max-width: 1000px;
  min-height: 70vh;
  padding: 20px;
  margin-bottom: 60px;
  h1 {
    margin-top: 6vh;
    margin-bottom: 20px;
    font-size: 38px;
    font-weight: 500;
    line-height: 1.4;
  }
  h5 {
    margin-top: 0px;
    margin-bottom: 50px;
    color: #777;
    font-size: 90%;
  }
  p {
    margin-top: 30px;
  }
  display: flex;
  > div {
    width: 50%;
  }
  img.dashboard {
    margin-top: 50px;
    margin-left: 100px;
    min-width: 500px;
    position: absolute;
  }

  .deco1 {
    /* margin: 100px 10px 0 0; */
    left: 10px;
    top: 100px;
    position: absolute;
  }
  .deco2 {
    /* margin: 500px 300px 0 0; */
    left: 300px;
    top: 600px;
    position: absolute;
  }

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    > div {
      width: 100%;
    }
    h1 {
      margin-top: 200px;
    }
    img.dashboard {
      /* position: relative; */
      z-index: -1;
      max-width: 100%;
      margin-top: -180px;
      margin-left: 150px;
    }

    .deco1 {
      left: 10px;
      top: 200px;
      position: absolute;
    }
    .deco2 {
      left: 500px;
      top: 300px;
      position: absolute;
    }
  }
`;

const Section = styled("div")`
  max-width: 1000px;
  margin: auto;
  margin-top: 50px;
  padding: 20px;

  .row {
    display: flex;
  }

  .col2 {
    width: 50%;
    margin-right: 20px;
  }

  .col3 {
    width: 33%;
    margin-right: 20px;
  }

  .col4 {
    width: 25%;
    margin-right: 20px;
  }
  .col2:last-of-type,
  .col3:last-of-type,
  .col4:last-of-type {
    margin-right: 0px;
  }

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const Content = styled("div")`
  max-width: 700px;
  margin: auto;
`;

const Headline = styled("h2")`
  margin: 50px;
  margin-top: 80px;
  font-size: 32px;
  clear: both;
  text-align: center;

  @media all and (max-width: 800px) {
    margin: 30px;
  }
`;

const Features = styled("div")`
  max-width: 1000px;
  margin: auto;
  margin-top: 100px;
  padding: 20px;

  .list {
    margin: 0;
    margin-top: 50px;
    .item {
      margin-bottom: 140px;
      list-style-type: none;
      display: flex;
      align-items: center;
      img {
        max-width: 300px;
        margin: 20px;
        vertical-align: middle;
      }
      :nth-child(even) {
        flex-direction: row-reverse;
        img {
          margin-left: 70px;
        }
      }
      :nth-child(odd) {
        img {
          margin-right: 70px;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .list .item {
      flex-direction: column;
      :nth-child(even) {
        flex-direction: column;
        img {
          margin-left: 0px;
        }
      }
      :nth-child(odd) {
        img {
          margin-right: 0px;
        }
      }
    }
  }
`;

const CTA = styled("div")`
  max-width: 800px;
  margin: auto;
  margin-top: 100px;
  text-align: center;
  padding: 20px;

  form {
    padding: 30px;
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    input {
      font-size: 20px;
      margin-right: 10px;
      margin-bottom: 20px;
      /* max-width: 240px; */
    }
  }
  button {
    min-width: 225px;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 20px;
  }
  .offer {
    margin-top: 100px;
    text-align: left;
    max-width: 600px;
    margin-left: 100px;
  }

  @media (max-width: 800px) {
    form {
      flex-direction: column;
    }
    .offer {
      margin-left: 0;
    }
  }
`;

const Quote = styled("div")`
  width: 600px;
  background: #f5f5f5;
  /* border-radius: 100px;
  padding: 80px; */
  /* margin: auto; */
  margin-top: 75px;
  font-size: 20px;
  line-height: 150%;

  border-radius: 40px;
  padding: 40px;
  padding-left: 50px;

  img {
    margin-left: -20px;
    margin-top: -20px;
  }

  &:nth-of-type(2) {
    float: right;
  }

  @media (max-width: 800px) {
    /* font-size: 16px; */
    margin: 20px auto;
    padding: 30px;

    &:nth-of-type(2) {
      float: none;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Footer = styled("footer")`
  margin-top: 100px;
  background: #333;
  color: #fff;
  > div {
    display: flex;
    margin: auto;
    max-width: 700px;
    padding: 40px;
    line-height: 200%;
    > div {
      width: 50%;
    }
    > div:last-child {
      text-align: right;
    }
  }
  a {
    color: #e370e2;
  }
  .twitter,
  .instagram {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
  @media (max-width: 800px) {
    padding: 20px;
    > div {
      flex-direction: column;
      > div {
        width: 100%;
      }
      > div:last-child {
        text-align: left;
      }
    }
  }
`;

const Button = styled("button")`
  font-weight: 500;
  background-color: ${props => props.color || "#ee1696"};
  border-radius: 8px;
  transition: background-color 0.3s, border-width 0.2s, box-shadow 0.2s;
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.2), 0 0 16px rgba(0, 0, 0, 0.15);
  border: 1px solid transparent;
  b {
    font-size: 42px;
    line-height: 0;
  }

  &:hover {
    background-color: ${props =>
      color(props.color || "#ee1696")
        .setAlpha(0.7)
        .toRgbString()};
    /* #fe26a6; */
  }
  &:active {
    box-shadow: inset 0px 0px 40px rgba(255, 255, 150, 0.7),
      0 0 12px rgba(0, 0, 0, 0.1);
    outline: none;
    /* background-color: #ff36b6; */
    padding-top: 19px;
    padding-bottom: 21px;
  }
  &:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  padding: 20px 30px;
  &.big {
    padding: 30px 50px;
  }
  &.big:active {
    padding-top: 32px;
    padding-bottom: 28px;
  }

  span {
    font-weight: normal;
    margin-top: 10px;
    display: block;
    font-size: 80%;
  }
`;

const Select = styled("select")`
  display: block;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: #888;
  }
  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }
  & option {
    font-weight: normal;
  }

  /* Support for rtl text, explicit support for Arabic and Hebrew */
  *[dir="rtl"] &,
  :root:lang(ar) &,
  :root:lang(iw) & {
    background-position: left 0.7em top 50%, 0 0;
    padding: 0.6em 0.8em 0.5em 1.4em;
  }

  /* Disabled styles */
  &:disabled,
  &[aria-disabled="true"] {
    color: graytext;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
  }
  &:disabled:hover,
  &[aria-disabled="true"] {
    border-color: #aaa;
  }
`;

const BigButton = styled(Button)`
  min-width: 400px;
  display: block;
  margin: auto;
  margin-top: 50px;

  @media (max-width: 420px) {
    min-width: 100%;
  }
`;

const Logo = styled("img")`
  width: 100px;
  margin-top: 20px;
`;

const Team = styled(Section)`
  .people {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  .col {
    width: 25%;
    padding: 20px;
  }
  .col:last-of-type {
    margin-right: 0px;
  }
  .col img {
    max-width: 170px;
    margin-bottom: 20px;
    display: block;
    border-radius: 100px;
  }
  .col label {
    font-size: 100%;
  }
  .col p {
    font-size: 16px;
    margin-top: 0;
  }
  .background-text {
    margin-left: -200px;
  }

  @media all and (max-width: 800px) {
    .people {
      margin-top: 0px;
    }
    .col {
      width: 50%;
    }
  }

  @media all and (max-width: 600px) {
    .col {
      width: 100%;
      text-align: center;
    }
    .col img {
      margin: 20px auto;
    }
  }
`;

const Email = props => (
  <a target="_blank" href={`mailto:${props.children}`}>
    {props.children}
  </a>
);

const ArrowBox = styled("div")`
  position: relative;
  background: #fff;
  border: 4px solid #d9effb;
  border-radius: 10px;
  padding: 20px 100px;
  max-width: 700px;
  margin: auto;
  margin-bottom: 20px;
  /* text-align: center; */

  h2 {
    color: #a9bfcb;
  }

  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 30px;
    margin-left: -30px;
  }
  &:before {
    border-color: rgba(217, 239, 251, 0);
    border-top-color: #d9effb;
    border-width: 36px;
    margin-left: -36px;
  }

  @media (max-width: 800px) {
    padding: 20px;
    padding-top: 15px;
  }
`;

const TwoColumns = styled("div")`
  display: flex;
  flex-direction: row;
  > div {
    width: 50%;
  }
`;

const Process = styled("div")`
  margin-top: 60px;
  .step {
    display: flex;
    clear: both;
    margin-bottom: 30px;

    .num {
      color: #aaa;
      width: 60px;
      font-size: 80px;
      float: left;
      padding-right: 30px;
      padding-top: 40px;
      margin-right: 10px;
      /* border-right: 3px solid rgba(0, 0, 0, 0.2); */
    }
  }
`;

const ReferenceSection = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;
const Reference = styled("div")`
  box-sizing: border-box;
  max-width: 48%;
  margin-right: 1%;
  margin-left: 1%;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;

  /* margin-left: 30px; */
  margin-bottom: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='108' height='21' viewBox='0 0 108 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.689 0.160004L4.08902 20.84L20.589 7.64H0.789024L17.289 20.84L10.689 0.160004Z' fill='%23FFD055'/%3E%3Cpath d='M32.5265 0.160004L25.9265 20.84L42.4265 7.64H22.6265L39.1265 20.84L32.5265 0.160004Z' fill='%23FFD055'/%3E%3Cpath d='M54.364 0.160004L47.764 20.84L64.264 7.64H44.464L60.964 20.84L54.364 0.160004Z' fill='%23FFD055'/%3E%3Cpath d='M76.2015 0.160004L69.6015 20.84L86.1015 7.64H66.3015L82.8015 20.84L76.2015 0.160004Z' fill='%23FFD055'/%3E%3Cpath d='M98.039 0.160004L91.439 20.84L107.939 7.64H88.139L104.639 20.84L98.039 0.160004Z' fill='%23FFD055'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 30px 30px;
  padding-top: 70px;

  @media all and (max-width: 800px) {
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 40px;
  }
`;

const Pkg = styled("div")`
  box-sizing: border-box;
  flex-grow: 1;
  /* width: 100%;   */

  padding: 0 30px;
  overflow: hidden;

  .label {
    background: #33cc55;
    color: white;
    font-size: 14px;
    padding: 4px;
    border-radius: 5px;
    vertical-align: super;
    white-space: nowrap;
  }
  .label.red {
    background: #cc4444;
  }
  .label.grey {
    background: #aaccbb;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
  }

  .price-tag {
    /* width: 100px;
    float: right; */
    text-align: right;

    .price-old {
      font-size: 24px;
      color: #ccc;
      text-decoration: line-through;
      display: block;
    }

    .price {
      color: #33cc55;
      font-size: 32px;
      display: block;
    }
  }

  p {
    font-size: 80%;
  }

  .note {
    font-size: 70%;
    color: #aaa;
  }
`;

const Pkgs = styled("div")`
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  button {
    margin-top: auto;
  }
  > div {
    width: 25%;
    border-left: 1px solid #eee;
  }

  @media all and (max-width: 950px) {
    > div {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media all and (max-width: 800px) {
    display: block;
    > div {
      padding-left: 30px;
      padding-right: 30px;

      width: 100% !important;
      .price-tag {
        text-align: left;
      }
      button {
        margin-bottom: 100px;
      }
    }
  }
`;

const BulletList = styled("ul")`
  margin-left: 0;
  padding-left: 0;

  li {
    font-size: 80%;
    list-style-type: none;
    padding-left: 40px;
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0C3.5816 0 0 3.5816 0 8C0 12.4184 3.5816 16 8 16C12.4184 16 16 12.4184 16 8C16 3.5816 12.4184 0 8 0ZM12.5656 6.1656L6.9656 11.7656C6.8096 11.9216 6.6048 12 6.4 12C6.1952 12 5.9904 11.9216 5.8344 11.7656L3.4344 9.3656C3.1216 9.0528 3.1216 8.5472 3.4344 8.2344C3.7472 7.9216 4.2528 7.9216 4.5656 8.2344L6.4 10.0688L11.4344 5.0344C11.7472 4.7216 12.2528 4.7216 12.5656 5.0344C12.8784 5.3472 12.8784 5.8528 12.5656 6.1656Z' fill='%23A7E521'/%3E%3C/svg%3E%0A")
      0px 4px no-repeat;
    padding-bottom: 10px;
  }
`;

const FAQ = styled("ol")`
  > li {
    margin-bottom: 20px;
  }
  ul li {
    list-item-type: none;
  }
`;

const MinProjectSection = styled("div")`
  margin: 100px;

  img {
    float: left;
    margin-top: -30;
    margin-right: 30;
    margin-bottom: 30;
  }

  @media all and (max-width: 800px) {
    margin: 20px;

    img {
      max-width: 100%;
      float: none;
      margin: 20px auto;
    }
  }
`;
const NotGuaranteedSection = styled("div")`
  margin: 100px;
  padding-left: 100px;

  img {
    max-width: 40%;
    float: right;
    transform: scaleX(-1);
    margin-left: 40px;
    margin-top: -100px;
    margin-right: -300px;
  }

  @media all and (max-width: 800px) {
    padding-left: 0;
    margin: 20px;
    img {
      display: none;
    }
  }
`;

export {
  Hero,
  Section,
  Content,
  Headline,
  ReferenceSection,
  Reference,
  Features,
  CTA,
  Quote,
  Footer,
  Button,
  Select,
  BigButton,
  Logo,
  Team,
  Email,
  ArrowBox,
  TwoColumns,
  Process,
  BulletList,
  FAQ,
  Pkgs,
  Pkg,
  MinProjectSection,
  NotGuaranteedSection
};
