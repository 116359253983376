import React from "react";
import Form from "antd/es/form/Form";
import Icon from "antd/es/icon";
import Input from "antd/es/input/Input";
// import Button from "antd/es/button/button";
import Checkbox from "antd/es/checkbox/Checkbox";
import Modal from "antd/es/modal/Modal";
import Spin from "antd/es/spin";

import styled from "styled-components";
import { Button as BigButton, Select } from "../components";

import packages from "./Packages";

const SubmitButton = styled(BigButton)`
  font-size: 20px;
  width: 100%;
`;

const FormContainer = styled("div")`
  input,
  label {
    font-size: 18px;
  }
  input {
    padding: 20px;
  }
`;

const ModalNoFooter = styled(Modal)`
  .ant-modal-title h2 {
    margin-bottom: 0;
  }
  .ant-modal-footer {
    padding: 1px;
    border-top: none;
  }
  .ant-modal-close-x {
    margin-top: 7px;
  }
`;

const ModalContent = styled("div")`
  font-size: 18px;
  display: flex;
  > div,
  > form {
    width: 50%;
  }
  > div:first-child div {
    padding-left: 0;
  }
`;

const getPackage = pkg => packages[pkg || Object.keys(packages).shift()];

class SignUpForm extends React.Component {
  render() {
    const { getFieldDecorator, currentPackage, onPackageChange } = this.props;
    return (
      <FormContainer>
        <label style={{ display: "block", margin: 8 }}>Pakettisi:</label>
        <Select value={currentPackage} onChange={onPackageChange}>
          {Object.keys(packages).map(key => (
            <option key={key} value={key}>
              {packages[key].name} {packages[key].price}&euro;
            </option>
          ))}
        </Select>
        <div style={{ marginBottom: 30 }} />
        <Form.Item label="Nimesi">
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "Nimi pakollinen" }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Lisää koko nimesi"
            />
          )}
        </Form.Item>
        <Form.Item label="Sähköposti">
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Sähköposti pakollinen" }]
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="email"
              placeholder="Lisää sähköpostiosoite"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("tos_agreed", {
            valuePropName: "checked",
            initialValue: true
          })(
            <Checkbox>
              Hyväksyn{" "}
              <a
                href="/terms"
                target="_blank"
                onClick={this.props.onShowTermsClick}
              >
                Käyttöehdot
              </a>
            </Checkbox>
          )}
        </Form.Item>
      </FormContainer>
    );
  }
}

class SignUpModal extends React.Component {
  state = {
    currentPackage: null
  };

  selectPackage = currentPackage => {
    this.setState({ currentPackage });
  };

  onPackageChange = e => {
    e.preventDefault();
    const currentPackage = e.target.value;
    this.setState({ currentPackage });
  };

  handleSubmit = e => {
    // console.log("handleSubmit", e);
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const { currentPackage } = this.state;
        const { content, ...pkg } = getPackage(currentPackage);
        this.props.onSubmit({
          ...values,
          option: currentPackage,
          pkg
        });
      }
    });
  };

  render() {
    const { getRef, loading } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { currentPackage } = this.state;
    const pkg = getPackage(currentPackage);
    getRef && getRef(this);

    return (
      <ModalNoFooter
        style={{ top: 20, maxWidth: 800 }}
        width={null}
        title={
          <h2>
            <Icon type="arrow-down" /> Aloita projektisi
          </h2>
        }
        visible={this.props.visible}
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        footer={[]}
      >
        <ModalContent>
          <div>{pkg.content}</div>
          <Form id="signup" onSubmit={this.handleSubmit} className="login-form">
            {/*
             */}

            <SignUpForm
              getFieldDecorator={getFieldDecorator}
              currentPackage={currentPackage}
              onPackageChange={this.onPackageChange}
              onShowTermsClick={this.props.onShowTermsClick}
            />
            <p style={{ textAlign: "right", color: "#aaa" }}>
              Ei luottokorttia vaadittu*
            </p>
            {loading ? (
              <Spin
                indicator={
                  <Icon type="loading" style={{ fontSize: 48 }} spin />
                }
              />
            ) : (
              <SubmitButton
                form="signup"
                type="submit"
                htmlType="submit"
                color="#33cc55"
              >
                Pyydä tarjous!
                <span>Olemme yhteydessä 24 tunnin sisään</span>
                {/* <span>
                  Start your case assesment on the following page &rarr;
                </span> */}
              </SubmitButton>
            )}
          </Form>
        </ModalContent>
      </ModalNoFooter>
    );
  }
}

// export default SignUpModal;
export default Form.create({ name: "signup" })(SignUpModal);
